:root {
    --primary: #0283EF;
    --background: #ebeff3;
    --teritary: #777;
}

/* Margins */
.m-all {
    margin: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mh-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mh-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mh-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mv-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mv-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}


/* Padding */
.p-all {
    padding: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}


.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pv-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

/* Gap */
.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

/* Text */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.b{
    font-weight: bold;
}

.b-200{
    font-weight: 200;
}

.b-300{
    font-weight: 300;
}

.b-400{
    font-weight: 400;
}

.b-500{
    font-weight: 500;
}

.b-600{
    font-weight: 600;
}

.b-700{
    font-weight: 700;
}

.b-800{
    font-weight: 800;
}

.b-900{
    font-weight: 900;
}

.row {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
}

.wrap-row {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.justifyCenter {
    justify-content: center;
}

.alignCenter {
    align-items: center;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.justifySplit {
    justify-content: space-between;
}

.gap {
    gap: 10px;
}

/* Paddings */
.paddingAll {
    padding: 10px;
}


/* Margins */
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

/* Gaps */
.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

/* Text */
.headerText {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #777777;
}


.a-link {
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

/* header */
.header {
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    background-color: white;
}


/* container */
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reg-container {
    padding: 20px;
}

.inner-container {
    max-width: 1000px;
    width: 100%;
    height: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.right {
    justify-content: right;
}

.left {
    justify-content: left;
}


/* Button */
.button {
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 13px;
    transition: all 0.2s ease-in-out;
    background-color: var(--primary);
    color: #ffffff;
    cursor: pointer;
    border: none;
}

.button:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.button-cta {
    padding: 5px 15px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #aab4bf;
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button-cta:hover {
    background-color: #aab4bf;
    color: white;
}

/* Input */

.input-label {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}

.text-input {
    padding: 10px;
    border: 0.5px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
}


.checkbox-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    margin: 0;
    padding: 0;
}

.select-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    appearance: none;
}

.textarea-input {
    padding: 10px;
    border: 1px solid var(--teritary);
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    resize: none;
    min-height: 100px;
}

.text-input:hover,
.textarea-input:hover {
    border: 2px solid var(--primary);
}

.backgroundLess {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
}

/*  */

.link {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    text-decoration: none;
}


/* navbar */
.navbar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #12344d;
    padding: 10px;
    position: static;
}

.navbarItem {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.navbarItem:hover {
    background-color: #2972ae;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.navbar-menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.1s ease-in-out;
    border-radius: 3px;
}

.navbarItemChild {
    /* move up */
    margin-top: 5px;
    padding: 10px;
    border-radius: 10px;
}

.navbarItemChild:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.component {
    background-color: #f5f7f9;
    border-bottom: 1px solid #cfd7df;
    padding: 10px 20px;
    position: sticky;
    width: 100%;
}

.component-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.card {
    background-color: white;
    border-bottom: 1px solid #cfd7df;
    padding: 10px 20px;
    width: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

/* add max width:1000 */
@media only screen and (max-width: 1000px) {
    .row {
        width: 100%;
        flex-wrap: wrap;
    }

    .navbar {
        padding: 10px;
    }

    .header {
        padding: 10px;
    }
}

/* modal */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.blockbuilder-branding {
    display: none;
}

.header-link {
    text-decoration: none;
    color: #000000;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
}


.nav-link{
    text-decoration: none;
    color: #000000;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
    transition: all 0.5s linear
}

.nav-link:hover{
    text-decoration: underline;
}



/* Cursor */
.cursor-pointer {
    cursor: pointer;
}

/* File */
.file-input-container {
    transition: all 0.2s ease-in-out;
    width: 100%;
    /* background-color: white; */
    padding: 10px 15px;
    border-radius: 20;
    border-style: dashed;
    border: 1px dashed gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-input-container.hover {
    border: 2px solid var(--primary);
    background-color: #f5f7f9;
}


.hide-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    /* IE 10+ */
    & {
        -ms-overflow-style: none;
    }

    /* Chrome  */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Safari and Chrome */
    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }
}