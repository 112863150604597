body {
  margin: 0;
  font-family:"Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebeff3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* video:first-child {
  display: none;
} */

video::-webkit-media-controls-start-playback-button {
  display: none;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 200;
    src: local('Poppins'), url(./fonts/Poppins-ExtraLight.ttf) format('opentype');
}


*, *::before, *::after {
  box-sizing: border-box;
}

img,svg,video,canvas,audio,iframe,embed,object {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6, a, span, button, input, label, li, ul, ol, textarea, select, option, img, svg, video, canvas, audio, iframe, embed, object {
  font-family: 'Poppins', sans-serif;
}