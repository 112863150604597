.ripple {
    /* background: rgba(255, 255, 255, 0.5); */
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 2s linear;
    background-color: red;
    width:20px;
    height:20px;
    animation-iteration-count: infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.2;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 0.2;
    }
}

